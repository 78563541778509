import ButtonContainer from './ButtonContainer';
import IconContainer from './IconContainer';

export const Icon = () => (
  <svg width={95} height={99} viewBox="0 0 95 99" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x={25.5} y={1} width={68.5} height={94.5} rx={2.5} fill="#FAFF00" stroke="#303030" strokeWidth={2} />
    <rect x={38} y={2} width={4} height={93} fill="#EDD500" />
    <path d="M87 51L87 90" stroke="white" strokeWidth={2.7} strokeLinecap="round" />
    <rect x={50} y={24} width={34} height={20} rx={2.5} fill="#B48EC0" stroke="#303030" strokeWidth={2} />
    <path
      d="M26.5 11H30.25C32.0449 11 33.5 12.4551 33.5 14.25C33.5 16.0449 32.0449 17.5 30.25 17.5H26.5V11Z"
      fill="#EDD500"
    />
    <path
      d="M26.5 29H30.25C32.0449 29 33.5 30.4551 33.5 32.25C33.5 34.0449 32.0449 35.5 30.25 35.5H26.5V29Z"
      fill="#EDD500"
    />
    <path
      d="M26.5 47H30.25C32.0449 47 33.5 48.4551 33.5 50.25C33.5 52.0449 32.0449 53.5 30.25 53.5H26.5V47Z"
      fill="#EDD500"
    />
    <path
      d="M26.5 65H30.25C32.0449 65 33.5 66.4551 33.5 68.25C33.5 70.0449 32.0449 71.5 30.25 71.5H26.5V65Z"
      fill="#EDD500"
    />
    <path
      d="M26.5 83H30.25C32.0449 83 33.5 84.4551 33.5 86.25C33.5 88.0449 32.0449 89.5 30.25 89.5H26.5V83Z"
      fill="#EDD500"
    />
    <path
      d="M6 3C8.48528 3 10.5 5.01472 10.5 7.5L10.5 85.8612C10.5 86.3236 10.4013 86.7807 10.2106 87.2019L6 96.5L1.78941 87.2019C1.59867 86.7807 1.5 86.3236 1.5 85.8612L1.5 7.5C1.5 5.01472 3.51472 3 6 3Z"
      fill="#B48EC0"
      stroke="#303030"
      strokeWidth={2}
    />
    <path d="M6 96L2.5359 87L9.4641 87L6 96Z" fill="#E0C2E9" />
    <path d="M1 8C1 5.23858 3.23858 3 6 3C8.76142 3 11 5.23858 11 8V13H1V8Z" fill="#FAFF00" />
    <rect x={19} y={26.5} width={12.5} height={6.5} rx={3.25} fill="#A579B2" stroke="#303030" strokeWidth={2} />
    <rect x={19} y={44.5} width={12.5} height={6.5} rx={3.25} fill="#A579B2" stroke="#303030" strokeWidth={2} />
    <rect x={19} y={62.5} width={12.5} height={6.5} rx={3.25} fill="#A579B2" stroke="#303030" strokeWidth={2} />
    <rect x={19} y={80.5} width={12.5} height={6.5} rx={3.25} fill="#A579B2" stroke="#303030" strokeWidth={2} />
    <line x1={38} y1={1} x2={38} y2={95.5} stroke="#303030" strokeWidth={2} />
    <line x1={1} y1={12.5} x2={10.5} y2={12.5} stroke="#303030" strokeWidth={2} />
    <line x1={1} y1={86} x2={10.5} y2={86} stroke="#303030" strokeWidth={2} />
    <line x1={43} y1={1} x2={43} y2={95.5} stroke="#303030" strokeWidth={2} />
    <path
      d="M6 3C8.48528 3 10.5 5.01472 10.5 7.5L10.5 85.8612C10.5 86.3236 10.4013 86.7807 10.2106 87.2019L6 96.5L1.78941 87.2019C1.59867 86.7807 1.5 86.3236 1.5 85.8612L1.5 7.5C1.5 5.01472 3.51472 3 6 3V3Z"
      stroke="#303030"
      strokeWidth={2}
    />
    <rect x={19} y={8.5} width={12.5} height={6.5} rx={3.25} fill="#A579B2" stroke="#303030" strokeWidth={2} />
  </svg>
);

const Diary = ({ active = false }) => {
  return (
    <ButtonContainer>
      <IconContainer active={active}>
        <Icon />
      </IconContainer>
    </ButtonContainer>
  );
};

export default Diary;
