const IconLightning = () => (
  <svg width={45} height={85} viewBox="0 0 45 85" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2153_3498)">
      <path
        d="M29.4526 38.0663H44.2143L10.1136 83.7726L16.7066 47.8517C16.8363 47.1386 16.2823 46.4837 15.5475 46.4837H0.785889L34.8866 0.777344L28.2935 36.6983C28.1639 37.4114 28.7179 38.0663 29.4526 38.0663Z"
        fill="#FFDF24"
      />
      <path
        d="M10.1137 84.5472C10.0076 84.5472 9.90541 84.5278 9.80325 84.4852C9.46535 84.3418 9.27675 83.9892 9.33962 83.6365L15.9327 47.7155C15.9602 47.5605 15.8934 47.452 15.8462 47.3978C15.803 47.3474 15.7048 47.2583 15.5437 47.2583H0.785964C0.491282 47.2583 0.220174 47.0955 0.082655 46.832C-0.0509345 46.5723 -0.0234308 46.2584 0.14945 46.022L34.2541 0.319564C34.4701 0.0289103 34.8631 -0.0757251 35.197 0.0676642C35.5349 0.211054 35.7235 0.563714 35.6607 0.916374L29.0676 36.8373C29.0401 36.9924 29.1069 37.1009 29.1541 37.1551C29.1973 37.2055 29.2955 37.2946 29.4566 37.2946H44.2183C44.5129 37.2946 44.784 37.4574 44.9216 37.7209C45.0551 37.9806 45.0276 38.2945 44.8548 38.5309L10.7462 84.2294C10.593 84.431 10.3573 84.5434 10.1137 84.5434V84.5472ZM2.34189 45.7081H15.5476C16.133 45.7081 16.6792 45.96 17.0564 46.4018C17.4296 46.8436 17.5868 47.421 17.4807 47.9907L11.4928 80.6176L42.6623 38.8409H29.4566C28.8712 38.8409 28.325 38.589 27.9478 38.1472C27.5746 37.7054 27.4174 37.128 27.5235 36.5583L33.5075 3.93143L2.34189 45.7081Z"
        fill="#2D2C2C"
      />
      <g
        style={{
          mixBlendMode: 'multiply',
        }}
        opacity={0.55}
      >
        <path
          d="M34.2579 2.1875L0.785889 46.4832L16.5102 46.5413L10.1136 83.7722L22.337 47.6729C22.9617 45.8244 21.5669 43.9138 19.5945 43.9138H9.34347C8.28654 43.9138 7.65003 42.7589 8.22368 41.887L34.2579 2.1875Z"
          fill="#EDD500"
        />
      </g>
      <path
        d="M24.942 35.3084C24.9067 35.3084 24.8674 35.3084 24.832 35.2968C24.5137 35.2348 24.3016 34.9325 24.3644 34.6186L27.9163 16.2338C27.9792 15.9199 28.2857 15.7106 28.6039 15.7726C28.9222 15.8346 29.1344 16.1369 29.0715 16.4508L25.5196 34.8356C25.4646 35.1147 25.2171 35.3084 24.942 35.3084Z"
        fill="white"
      />
      <path
        d="M23.72 60.5754C23.6021 60.5754 23.4803 60.5405 23.3782 60.4669C23.1149 60.2809 23.0521 59.9166 23.2407 59.6569L34.2579 44.4228C34.4465 44.1631 34.8158 44.1011 35.079 44.2871C35.3423 44.4732 35.4052 44.8374 35.2166 45.0971L24.1994 60.3312C24.0854 60.4901 23.9047 60.5754 23.72 60.5754Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2153_3498">
        <rect width={45} height={84.5455} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IconLightning;
