import PageContainer from '../components/Wrappers';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import React, { useState } from 'react';
import { useApp } from '../contexts/AppContext';
import WebApp from '@twa-dev/sdk';
import { useEffectOnce } from 'react-use';
import { InvoiceStatuses } from '@twa-dev/types';
import { postShopLink } from '../lib/api';
import { IProduct } from '../lib/types';
import Row from '../components/Row';
import Loading from '../components/Loading';
import { doHapticFeedback } from '../lib/utils';
import BuyCard from '../components/Products/BuyCard';
import BuyButton from '../components/Products/BuyButton';
import IconStar from '../components/Icons/IconStar';

const IntroText = styled.div`
  //width: 776px;
  width: 880px;

  font-family: Roboto, sans-serif;
  font-size: 35px;
  font-weight: 400;
  line-height: 40px;
  text-align: center;

  margin-bottom: 40px;
`;

const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Label = styled.div`
  font-family: var(--font), sans-serif;
  font-size: 35px;
  font-weight: 400;
  line-height: 35px;
  text-align: left;
  color: #faff00;
  text-transform: uppercase;
`;

const Description = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 35px;
  font-weight: 500;
`;

const StatsContainer = styled.div`
  position: relative;
  text-align: center;

  width: 590px;
  padding: 20px 80px 20px 80px;
  background: #232323;
  border-radius: 45px;
  border: 2px solid #a7a7a3;
  margin-bottom: 40px;
`;

const StatsLabel = styled.div`
  color: white;
  font-family: var(--font), sans-serif;
  font-size: 35px;
  font-weight: 400;
  line-height: 44.56px;
  text-align: left;
  text-transform: uppercase;
`;

const Value = styled.div`
  color: #faff00;
  font-family: var(--font), sans-serif;
  font-size: 35px;
  font-weight: 400;
  line-height: 44.56px;
  text-align: left;
`;

const FriendsSpirit = () => {
  const { updateApp, setEvent, app } = useApp();
  const { t } = useTranslation();
  const [count, setCount] = useState(1);
  const [isProcessing, setIsProcessing] = useState<null | string>(null);

  const handleBuy = async (product: IProduct) => {
    doHapticFeedback();
    setIsProcessing(product.id);
    setCount(product.virtualFriendsCount);
    const invoiceUrl = await postShopLink(product.id);
    WebApp.openInvoice(invoiceUrl.link);
  };

  useEffectOnce(() => {
    const handleInvoiceClosed = ({ status }: { status: InvoiceStatuses }) => {
      if (status === 'paid') {
        setEvent({ type: 'purchased', product: 'friend', count });
        setTimeout(updateApp, 5000);
      }
      setIsProcessing(null);
    };

    WebApp.onEvent('invoiceClosed', handleInvoiceClosed);
    return () => {
      WebApp.offEvent('invoiceClosed', handleInvoiceClosed);
    };
  });

  const products = app.config.shop.products.filter((product) => product.type === 'virtual-friends');

  return (
    <PageContainer vcenter>
      {!app.state.friendsVirtualCount && <IntroText>{t('friends.spirit.intro')}</IntroText>}
      {!!app.state.friendsVirtualCount && (
        <StatsContainer>
          <Row spread={true}>
            <StatsLabel>{t('friends.spirit.friendsCount')}</StatsLabel>
            <Value>{app.state.friendsVirtualCount}</Value>
          </Row>
        </StatsContainer>
      )}

      {products.map((product) => {
        const isProductProcessing = isProcessing && isProcessing === product.id;

        return (
          <BuyCard key={product.id}>
            <img alt="spirit" src={`/img/friends/spirit_x${product.virtualFriendsCount}.png`} />
            <RightSide>
              <Label>
                {product.virtualFriendsCount} {t('friends.spirit.friend', { count: product.virtualFriendsCount })}
              </Label>
              <Description>{t('friends.spirit.willHelpImprove')}</Description>
              <BuyButton onClick={() => handleBuy(product)} disabled={!!isProcessing}>
                {!isProductProcessing && (
                  <>
                    {t('friends.spirit.buyFor')} {product.totalAmount} <IconStar />
                  </>
                )}
                {isProductProcessing && <Loading />}
              </BuyButton>
            </RightSide>
          </BuyCard>
        );
      })}
    </PageContainer>
  );
};

export default FriendsSpirit;
