import styled from 'styled-components';

export const BuyButton = styled.button`
  width: 427px;
  height: 95px;
  border-radius: 50px;
  background: #faff00;

  text-transform: uppercase;
  font-family: var(--font), sans-serif;
  font-size: 35px;

  :disabled {
    opacity: 0.5;
    filter: grayscale(100);
  }
`;

export default BuyButton;
