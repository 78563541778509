import styled from 'styled-components';
import TaskCard from '../components/pages/Tasks/TaskCard';
import { useApp } from '../contexts/AppContext';
import { IconCoin } from '../components/Icons/IconCoin';
import React, { useState } from 'react';
import { env } from '../lib/env';
import Row from '../components/Row';
import { MainButton } from '../components/MainButton';
import { deleteUser, patchUserProfile } from '../lib/api';
import IconSepuku from '../components/Icons/IconSepuku';
import { useTranslation } from 'react-i18next';
import PageContainer from '../components/Wrappers';
import { motion } from 'framer-motion';
import { useEffectOnce } from 'react-use';
import { doHapticFeedback } from '../lib/utils';
import Toggle from 'react-toggle';
import ToggleLabel from '../components/ToggleLabel';

const Header = styled.div`
  width: 710px;
  color: #fffefd;
  text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 66px */
  letter-spacing: -1.2px;
  text-transform: uppercase;
  margin-bottom: 30px;

  svg {
    margin-top: -30px;
    width: 200px;
    height: 200px;
  }
`;

const Tasks = () => {
  const { tasks, user } = useApp();
  const { t } = useTranslation();
  const [showPromo, setShowPromo] = useState(user.showPromo);
  const allowSepuku = (!env.isProd || env.isVercel) && localStorage.getItem('debug') === 'on';

  const pendingPromoTasksExist = tasks.filter((task) => task.isPromo && !task.isCompleted).length > 0;
  const filteredTasks =
    showPromo && pendingPromoTasksExist ? tasks.filter((task) => task.isPromo) : tasks.filter((task) => !task.isPromo);

  useEffectOnce(() => {
    doHapticFeedback();
  });

  const handlePromoChange = async () => {
    setShowPromo((prevShowPromo) => !prevShowPromo);
    await patchUserProfile(!showPromo);
  };

  const tasksUpdated = filteredTasks.map((task) => {
    task.url = task.url.replace('$TG_USER_ID', user.id.toString()).replace('$DAPP_CODE', user.extra.dappCode || '');
    return task;
  });

  return (
    <PageContainer>
      <motion.div initial={{ x: 64 }} animate={{ x: 0 }} transition={{ duration: 0.5, type: 'spring', bounce: 0.5 }}>
        <Header>
          <IconCoin />
          <br />
          {t('tasks.moreCoins')}
        </Header>
      </motion.div>
      <Row margin="0 0 30px">
        <ToggleLabel>
          <Toggle icons={false} defaultChecked={showPromo} onChange={handlePromoChange} />
          {t('tasks.showPromoTasks')}
        </ToggleLabel>
      </Row>
      <div style={{ maxHeight: '861px', overflow: 'scroll' }}>
        {tasksUpdated.map((task, i) => (
          <TaskCard key={i} task={task} />
        ))}
        {allowSepuku && (
          <Row margin="30px 0 37px" gap="40px">
            <MainButton
              onClick={async () => {
                await deleteUser();
                alert('User removed. Tap ok to reload app');
                window.location.reload();
              }}
            >
              commit seppuku
              <IconSepuku />
            </MainButton>
          </Row>
        )}
      </div>
    </PageContainer>
  );
};

export default Tasks;
