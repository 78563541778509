import { ITask } from './types';
import { IFlock } from './types/flock';

export type Event =
  | null
  | CloudEvent
  | SpeedEvent
  | LuckEvent
  | CalmEvent
  | TaskCompleteEvent
  | FlockJoinedEvent
  | PurchasedEvent;

export type CloudEvent = {
  type: 'turbo';
};

export type SpeedEvent = {
  type: 'speed';
};

export type CalmEvent = {
  type: 'fuel';
};

export type LuckEvent = {
  type: 'luck';
};

export type TaskCompleteEvent = {
  type: 'taskComplete';
  task: ITask;
};

export type FlockJoinedEvent = {
  type: 'flockJoined';
  flock: IFlock;
};

export type PurchasedEvent = {
  type: 'purchased';
  product: 'friend' | 'vial';
  count: number;
};

export const isCalmEvent = (event: Event): event is CalmEvent => event?.type === 'fuel';
export const isLuckEvent = (event: Event): event is LuckEvent => event?.type === 'luck';
export const isCloudEvent = (event: Event): event is CloudEvent => event?.type === 'turbo';
export const isSpeedEvent = (event: Event): event is SpeedEvent => event?.type === 'speed';
export const isTaskCompleteEvent = (event: Event): event is TaskCompleteEvent => event?.type === 'taskComplete';
export const isFlockJoinedEvent = (event: Event): event is FlockJoinedEvent => event?.type === 'flockJoined';
export const isPurchasedEvent = (event: Event): event is PurchasedEvent => event?.type === 'purchased';
