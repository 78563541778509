import styled from 'styled-components';

import PageContainer from '../components/Wrappers';
import MenuBoosts from '../components/Boosts';
import { useTranslation } from 'react-i18next';
import React from 'react';
import BuyVialButton from '../components/pages/Boost/BuyVialButton';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 50px;
  width: 100%;
  > p {
    text-align: center;
    font-size: 34px;
    width: 800px;
    font-style: italic;
    color: #eee;
    line-height: 1.3;
  }
`;

const Header = styled.div`
  width: 710px;
  color: #fffefd;
  text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 76px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -1.2px;
  text-transform: uppercase;
`;

const Boosts = () => {
  const { t } = useTranslation();

  return (
    <PageContainer vcenter>
      <Container>
        <BuyVialButton />
        <Header>{t('boosts.boosts')}</Header>
        <MenuBoosts />
        <p>
          {t('boosts.byUpgradingYourBoosts.part1')}
          <strong>{t('boosts.byUpgradingYourBoosts.highlight')}</strong>
          <br />
          {t('boosts.byUpgradingYourBoosts.part2')}
        </p>
      </Container>
    </PageContainer>
  );
};

export default Boosts;
