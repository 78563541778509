import { useQuery } from 'react-query';
import { getLeaderboard, getLotteryWinners, getPurchases } from './back';

export const useLeaderboard = (balance_min: number, balance_max: number) =>
  useQuery(['leaderboard', balance_min, balance_max], () => getLeaderboard(balance_min, balance_max), {
    staleTime: 60000,
  });

export const useLotteryWinners = (uuid: null | string) =>
  useQuery(['lottery', uuid], () => (uuid ? getLotteryWinners(uuid) : []), {
    staleTime: 3600000,
  });

// extraFeatureCount can be extraFuelDuration or friendsVirtualCount, etc...
export const usePurchases = (extraFeatureCount: number) =>
  useQuery(['purchases', extraFeatureCount], () => getPurchases(), {
    staleTime: 3600000,
    placeholderData: [],
  });
