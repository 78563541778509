import styled from 'styled-components';

const BuyCard = styled.div`
  width: 850px;
  padding: 40px 45px;
  top: 296px;
  left: 70px;
  gap: 64px;
  border-radius: 50px;

  background: #32363ca3;
  margin-bottom: 10px;

  border: 1.5px solid #ebebeb9c;

  display: flex;
  flex-direction: row;
  img {
    border: 1.5px solid #a4a5a2;
    border-radius: 30px;
    background: #d3baff;
  }
`;

export default BuyCard;
