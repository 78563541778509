import styled from 'styled-components';
import React from 'react';

const IconStar = styled((props) => (
  <svg width="36" height="34" viewBox="0 0 36 34" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19.2646 0.776649L23.7355 9.73653H23.7339C23.9403 10.1482 24.337 10.4328 24.7955 10.4987L34.7928 11.9347V11.9363C35.3245 12.0119 35.7651 12.3801 35.9309 12.885C36.0968 13.3899 35.9586 13.9447 35.5749 14.3145L28.3416 21.2882C28.0083 21.6082 27.8571 22.0697 27.9351 22.5231L29.6423 32.3705C29.7333 32.8931 29.5154 33.4222 29.0813 33.7341C28.6472 34.0461 28.0717 34.0863 27.597 33.8402L18.6568 29.1899C18.2455 28.9776 17.7545 28.9776 17.3432 29.1899L8.40303 33.8402C7.9283 34.0863 7.35277 34.0461 6.91867 33.7341C6.48294 33.4222 6.26672 32.8931 6.35774 32.3689L8.06486 22.5215V22.5231C8.1429 22.0697 7.9917 21.6082 7.65841 21.2882L0.42513 14.3145C0.0414359 13.9447 -0.0967535 13.3899 0.0690763 12.885C0.23491 12.3801 0.675491 12.0119 1.20716 11.9363L11.2045 10.5003V10.4987C11.663 10.4328 12.0597 10.1482 12.2661 9.73653L16.7354 0.776652C16.9727 0.300707 17.4637 0 18.0003 0C18.5368 0 19.0278 0.300695 19.2652 0.776652L19.2646 0.776649Z"
      fill="#363636"
    />
  </svg>
))`
  position: relative;
  top: 1px;
  margin-left: 10px;
`;

export default IconStar;
